/* ! About */
.about {
  min-height: 100vh;
  display: flex;
  transition: 1s;
}

// .about:hover {
//   margin-left: 2.9rem;
//   transition: 1s;
//   // cursor: pointer;
// }

.about-main-heading {
  font-size: 13rem;
  letter-spacing: .3rem;
  text-transform: uppercase;
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  text-align: center;
  // line-height: .2;
  font-family: "didot";
  transition: .4s;
  opacity: .4;
  animation: open 4s ease-in-out;
  margin-left: -2.9rem;
}

.about-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-branding-picture {
  display: flex;
  align-items: flex-start;
  width: 80%;
  margin: 0 auto;
}

.my-photo-positioning {
  margin-top: 6rem;
  margin-left: 6rem;
  animation: open 8s ease-in-out;
}

.positioning-brand-statement-paragraph {
  margin-left: 27rem;
  margin-top: -14.5rem;
  animation: open 5s ease-in-out;
  z-index: 1;
  width: 450px;
}

.positioning-about-main-heading {
  margin-top: 3rem;
  animation: open 2s ease-in-out;
  width: 480px;
}

.skills-positioning-container {
  width: 80%;
  margin-top: 7rem;
  h3 {
    font-size: 2rem;
    font-family: 'DM Serif Display', serif;
    border-bottom: 1px solid rgba(114, 0, 0, .4);
  }
  h4 {
    font-size: 1.5rem;
    font-family: 'DM Serif Display', serif;
    margin-top: 1rem;
    // color: rgb(114, 0, 0);
  }
}

.skills-icons {
  width: 100%;
  margin: 1rem;
  transition: 2s;
  display: block;
  padding: .5rem;
  border-radius: 10px;
}

.skills-icons-positioning {
  display: flex;
  margin-bottom: 2rem;
  animation: open 2s ease-in-out;
}

.overlay {
  margin-top: 6rem;
  margin-left: .5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25%;
  width: 120%;
  opacity: 0;
  transition: .5s ease;
  font-family: 'Raleway', sans-serif;
}

.container {
  position: relative;
  width: 100px;
  margin: 1rem;
}

.container:hover {
  cursor: url('../images/circleTwo.svg'), auto;
}

.container:hover .overlay {
  opacity: 1;
  // cursor: pointer;
}

.text {
  color: black;
  font-size: 20px;
  position: absolute;
  margin-top: .5rem;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.my-photo {
  width: 300px;
  opacity: .9;
  margin-left: 3rem;
  // border-radius: 10px;
}

