/* ! Projects section */
.projects {
  min-height: 100vh;
  display: flex;
  align-items: center;
  transition: 1s;
}

.projects-main-heading {
  font-size: 13rem;
  letter-spacing: .3rem;
  text-transform: uppercase;
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  text-align: center;
  // line-height: .2;
  font-family: "didot";
  transition: .4s;
  opacity: .4;
  animation: open 4s ease-in-out;
  margin-left: -2.9rem;
}

// .projects:hover {
//   margin-left: 2.9rem;
//   transition: 1s;
// }

.changing-according-profile {
  padding-top: 20px;
}

details[open] summary ~* {
  animation: open 1s ease-in-out;
}

@keyframes open {
  0% {
    opacity: 0;
  } 
  100% {
    overflow: 1;
  }
}

details summary::-webkit-details-marker {
  display: none;
}

details summary {
  width: 100%;
  padding: .5rem 0;
  border-bottom: 1px solid black;
  position: relative;
  cursor: url('../images/circleTwo.svg'), auto;
  font-size: 1.25rem;
  font-weight: 300;
  list-style: none;
  animation: open 2s ease-in-out;
}

details summary::after {
  content: '+';
  color: rgb(114, 0, 0);
  margin-top: 1rem;
  position: absolute;
  font-size: 3rem;
  line-height: 0;
  right: 0;
  transform-origin: center;
  transition: 200ms linear;
}

details[open] summary::after {
  transform: rotate(45deg);
  font-size: 4rem;
}

details summary {
  outline: 0;
}

details p {
  font-size: .95rem;
  margin: 0 0 1rem;
  padding-top: 1rem;
}

.project-details-width {
  width: 75%;
  margin-left: 8rem;
  margin-right: 4rem;
}

.project-images-resize {
  width: 500px;
  padding: 1rem;
}

.summary-positioning {
  display: flex;
  align-items: center;
  h3 {
    font-size: 1.5rem;
    font-family: didot;
  }
}

.project-icon-container {
  height: 75px;
  width: 75px;
}
.project-icon {
  padding-right: 1rem;
  // width: 75px;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.accordion-text {
  display: flex;
}

.tools-icons-positioning {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.tools-icons-size {
  width: 50px;
  height: 50px;
  margin: 15px 15px 0;
  border-radius: 10px;
}

.accordion-text-paragraph {
  font-size: 1.3rem;
  font-family: 'Raleway', sans-serif;
}

.positioning-projects-buttons {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  ul {
    display: flex;
    li {
      list-style: none;
      a {
        text-decoration: none;
        color: inherit;
        font-family: didot;
      }
      .cta {
        position: relative;
        margin: 0 10px;
        padding: 17px 22px;
        transition: all .2s ease;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          border-radius: 28px;
          background: rgba(114, 0, 0, .2);        // #FFAB9D
          width: 56px;
          height: 56px;
          transition: all .3 ease;
        }
        span {
          position: relative;
          font-size: 16px;
          line-height: 18px;
          font-weight: 900;
          letter-spacing: .15em;
          text-transform: uppercase;
          vertical-align: middle;
        }
        svg {
          position: relative;
          top: 0;
          margin-left: 10px;
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke: #111;
          stroke-width: 2;
          transform: translateX(-5px);
          transition: all .3 ease;
        }
        &:hover {
          &:before {
            width: 100%;
            // background: rgba(114, 0, 0, .8);
          }
          svg {
            transform: translateX(0)
          }
        }
        &:active {
          transform: scale(.96);
        }
      }
    }
  }
}