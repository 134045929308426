/* ! Contacts */
.contacts {
  margin-top: 8rem;
  min-height: 100vh;
  animation: open 4s ease-in-out;
}

// Contact form

.container-contact {
  border: solid 3px black;
  max-width: 768px;
  margin: 60px auto 8rem;
  position: relative;

  h3 {
    font-family: 'DM Serif Display';
    font-size: 3rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  .underline {
    border-bottom: solid 2px black;
    margin: -0.512em auto;
    width: 80px;
  }

  .icon_wrapper {
    margin: 50px auto 0;
    display: flex;
    justify-content: center;
    img {
      width: 60px;
      margin: 0 25px;
      padding: .5rem;
      border-radius: 10px;
    }
  }



  .email {
    float: right;
    width: 45%;
  }

  input[type='text'], [type='email'], .select-subject, textarea {
    background: none;
    border: none;
    border-bottom: solid 2px black;
    color: #474544;
    font-size: 1.000em;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0em 0 1.875em 0;
    padding: 0 0 0.875em 0;
    text-transform: uppercase;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  input[type='text']:focus, [type='email']:focus, textarea:focus {
    outline: none;
    padding: 0 0 0.875em 0;
  }

  .message {
    float: none;
  }
  
  .name {
    float: left;
    width: 45%;
  }

  .select-subject {
    background: url('../images/SVGIcons/arrow-down.svg') no-repeat right;
    background-size: 16px;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
  
  .select-subject::-ms-expand {
    display: none;
  }

  .subject {
    width: 100%;
  }
  
  .telephone {
    width: 100%;
  }
  
  textarea {
    line-height: 150%;
    height: 150px;
    resize: none;
    width: 100%;
  }
  
  ::-webkit-input-placeholder {
    color: #474544;
  }
  
  :-moz-placeholder { 
    color: #474544;
    opacity: 1;
  }
  
  ::-moz-placeholder {
    color: #474544;
    opacity: 1;
  }
  
  :-ms-input-placeholder {
    color: #474544;
  }
  
  .form_button {
    background: none;
    border: solid 2px black;
    color: black;
    cursor: pointer;
    display: inline-block;
    font-family: 'DM Serif Display';
    font-size: 0.875em;
    font-weight: bold;
    outline: none;
    padding: 20px 35px;
    text-transform: uppercase;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .form_button:hover {
    background-color: rgba(114, 0, 0, 1);
    color: antiquewhite;
  }
}

form {
  padding: 37.5px;
  margin: 50px 0;
}

button {
  overflow: visible;
}

button, .select-subject {
  text-transform: none;
}

button, input, .select-subject, textarea {
  color: #5A5A5A;
  font: inherit;
  margin: 0;
}

input {
  line-height: normal;
}

textarea {
  overflow: auto;
}



// -- End Contact form

