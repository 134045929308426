@import 'home';
@import 'about';
@import 'projects';
@import 'experiences';
@import 'contacts';
@import 'footer';
@import 'media-query';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}