* {
  // background: antiquewhite;
  ul {
    margin: 0;
  }
  ul.dashed {
    list-style-type: none;
    padding-left: 1rem;
    }
  ul.dashed > li {
    text-indent: -5px;
  }
  ul.dashed > li:before {
    content: "-";
    text-indent: -5px;
  }
}

// home scroll

.scroll-home {
  position: fixed;
  bottom: 800px;
  right: 1.05rem;
  width: 60px;
  height: 60px;
  background: antiquewhite url('../images/SVGIcons/home-icon.svg');
  border-radius: 50%;
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 99997;
  visibility: hidden;
  opacity: 0;
  transition: .5s;
}

.scroll-home.active {
  bottom: 40px;
  visibility: visible;
  opacity: 1;
}

// toggle effect

:root {
  --clr-light: antiquewhite;
  --clr-dark: black;
  --clr-primary: antiquewhite;

  --foreground: var(--clr-dark);
  --background: var(--clr-light);

}

.darkmode {
  --clr-light: antiquewhite;
  --clr-dark: black;
  --clr-primary: antiquewhite;

  --foreground: var(--clr-light);
  --background: var(--clr-dark);

  nav a {
    color: antiquewhite;
    opacity: .6;
  }
  nav:hover a h2 {
    opacity: 1;
  }
  .scrolls {
    color: antiquewhite;
    opacity: 1;
  
    .scroll-indicator-line {
      background-color: antiquewhite;
    }
  }
  .text {
    color: antiquewhite;
  }
  .skills-icons {
    background: antiquewhite;
    padding: .5rem;
  }
  .skills-positioning-container h3 {
    border-bottom: 1px solid rgba(114, 0, 0, 1);
  }
  details summary {
    border-color: antiquewhite;
  }

  .positioning-projects-buttons {
    .cta {
      &:before {
        background: rgba(114, 0, 0, .8)
      }
      svg {
        stroke: antiquewhite;
      }
    }
  }
  .container-contact {
    border-color: antiquewhite;
    .underline {
      border-bottom-color: antiquewhite;
    }
    input[type='text'], [type='email'], .select-subject, textarea {
      border-bottom-color: antiquewhite;
      color: antiquewhite;
      opacity: .75;
    }
    ::-webkit-input-placeholder {
      color: antiquewhite;
      opacity: .75;
    }
    
    :-moz-placeholder { 
      color: antiquewhite;
      opacity: .75;
    }
    
    ::-moz-placeholder {
      color: antiquewhite;
      opacity: .75;
    }
    
    :-ms-input-placeholder {
      color: antiquewhite;
      opacity: .75;
    }

    .form_button {
      border-color: antiquewhite;
      color: antiquewhite;
    }

    .select-subject {
      background: url('https://svgshare.com/i/_Wk.svg') no-repeat right;
      background-size: 16px;
    }
  }

  .tools-icons-size {
    background: antiquewhite;
    padding: .5rem;
  }

  .icon_wrapper {
    img {
      background-color: antiquewhite;
    }
  }

  .experiences-right-side {
    .work-experiences-icons {
      background: url('https://svgshare.com/i/_Yo.svg');
      background-size: 300px;
    }
  }

  .fill-svg {
    fill: antiquewhite;
  }

  .container-contact {
    background-color: rgba(250,235,215, .1);
  }
  
}


body {
  background: var(--background);
  color: var(--foreground);
}

.dark-mode-toggle {
  position: absolute;
  z-index: 100;
  color: var(--foreground);
  border: 2px solid currentColor;
  padding: 4px;
  background: transparent;
  cursor: pointer;
  border-radius: 5px;
  width: 30px;
  height: 30px;
}

.toggle-button-position {
  // position: relative;
  display: flex;
  justify-content: center;
  button {
    margin-top: 7vh;
  }
}

// scrolling effect

.scrolls {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 1.25em;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  opacity: .4;
  display: block;
  padding-top: 40px;
  padding-bottom: 10px;
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-right: 2.5rem;

  .center {
    font-size: 0.4rem;
  }

  .scroll-indicator-line {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    display: block;
    width: 1px;
    height: 25px;
    background-color: #000;
    margin: auto;
    content: '';
  }
}

.outside-buttons {
  min-height: 2vh;
}

.homepage-container {
  min-height: 98vh;
  font-family: "didot";
}

header {
  // margin: 2rem;
  min-height: 100vh;
  position: relative;
}

.putting-margin-to-home-page {
  padding: 2rem;
  // margin-bottom: 4rem;
}

nav a {
    font-size: 1.5rem;
    opacity: .3;
    text-decoration: none;
    color: black;
    transition: 0.2s;
    position: relative;
    display: inline-block;
}

nav:hover a h2 {
  cursor: url('../images/circleTwo.svg'), auto;
  padding: 2rem;
  margin: -2rem;
}

nav a h2:hover {
  color: rgb(114, 0, 0);
}


.cursor-normal {
  cursor: url('../images/circle.svg') 12 10, auto;
}

.top-navbar {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}

// .top-navbar:hover a h2 {
//   color: rgb(114, 0, 0);;
//   cursor: url('../images/circleTwo.svg'), auto;
// }

.bottom-navbar {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 4rem;
  padding-bottom: 2rem;
}

.navbar-contacts-directions {
  transform: rotate(360deg);
  writing-mode: vertical-lr;
}

.my-introduction-heading {

  margin-top: 18rem;
  .tominc-media-query {
    display: flex;
  }
  h1 {
    text-transform: uppercase;
    font-size: 12rem;
    color: rgb(114, 0, 0);
    line-height: 1;
  }
  h2 {
    font-size: 6rem;
    margin-top: -2rem;
    margin-left: 2rem;
  }
}



.section-home-1 {
  height: 100vh;
  padding: 10em;
}

.custom-p {
  opacity: 0;
  transition: opacity 6s;
}

.scroll-class {
  opacity: 1;
}








