// ! Sorting navbar icon at 1575px
@media only screen and (max-width: 1575px) {

  // -- Responsive navbar -- 

  .putting-margin-to-home-page {
    padding: 0;
  }

  #hamburger {
    position: fixed;
    top: 2rem;
    right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    cursor: pointer;
    transition: all .5s ease-in-out;
    z-index: 999999;
  }
  .hamburger-icon {
    width: 50px;
    height: 9px;
    background: rgb(144, 0, 0);
    border-radius: 9px;
    transition: all .5s ease-in-out;
  }
  .hamburger-icon::before,
  .hamburger-icon::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 9px;
    background: rgb(144, 0, 0);
    border-radius: 9px;
    transition: all .5s ease-in-out;
    z-index: 9999999;
  }
  .hamburger-icon::before {
    transform: translateY(-16px);
  }
  .hamburger-icon::after {
    transform: translateY(16px);
  }

  #hamburger.active .hamburger-icon {
    transform: translateX(-50px);
    background: transparent;
  }
  #hamburger.active .hamburger-icon::before {
    transform: rotate(45deg) translate(35px, -35px);
  }
  #hamburger.active .hamburger-icon::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }


  #side-navbar {
    position: fixed;
    top: 0;
    right: -100%;
    width: calc(100% - 500px);
    // padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    min-height: 100vh;
    z-index: 99998;
    transition: 1s ease-in-out;
    nav a {
      opacity: .8;
      transition: 1s;
    }
    .top-navbar {
      justify-content: unset;
      align-items: unset;
      flex-direction: column;
      h2 {
        color: antiquewhite;
        font-size: 4rem;
      }
    }
    .bottom-navbar {
      bottom: unset;
      justify-content: unset;
      align-items: unset;
      flex-direction: column;
      h2 {
        color: antiquewhite;
        font-size: 4rem;
      }
      .navbar-contacts-directions {
        writing-mode: horizontal-tb;
      }
    }
  }

  #side-navbar.active {
    right: 0;
  }

  // I have to use this to prevent when you click on a thing it directs you to the right page.
  nav:hover a h2 {
    pointer-events: none;
    padding: 0;
    margin: 0;
  }

  .darkmode {
    #side-navbar {
      background: antiquewhite;
    }
    .top-navbar a, .bottom-navbar a {
      h2 {
        color: black !important;
      }
    }
    .about-main-heading, .experiences-main-heading, .projects-main-heading, .contact-main-heading  {
      color:antiquewhite;
      opacity: .2 !important;
    }
  }

// -- End navbar --

.my-introduction-heading {
  h1 {
    margin-left: 3rem;
  }
  h2 {
    margin-left: 5rem;
  }
}

}

// ! Sorting about page at 1500px
@media only screen and (max-width: 1500px) {

  // ? About page

  .positioning-about-main-heading {
    flex-direction: column;
    margin-top: 11rem;
    // width: 350px;
    p {
      margin-right: -3rem;
    }
  }

  .skills-positioning-container {
    width: 75%;
    margin-left: -5rem;
  }

  .my-photo-positioning {
    margin-top: 11rem;
    margin-left: 6rem;
  }

  .positioning-brand-statement-paragraph {
    margin-top: 4rem;
    margin-left: -12rem;
    width: 800px;
  }

  .skills-icons-positioning {
    flex-wrap: wrap
  }

  .container {
    .skills-icons {
      width: 75px;
    }
  }

}

// ! max-width: 1330px - About section
@media only screen and (max-width: 1350px) {
  // ? About page

  .positioning-brand-statement-paragraph {
    margin-left: -18rem;
  }

}

// ! max-width 1280x800 - Large screen

@media only screen and (max-width: 1280px) {

  // ? Home section

  .my-introduction-heading {
    h2 {
      font-size: 5rem;
    }
  }

  // ? About section

  .about-main-container {
    margin-left: -5rem;
  }

  .about-main-heading {
    font-size: 13rem;
    margin-top: 10rem;
    opacity: .05 !important;
    z-index: -1;
    margin-left: -3.2rem;
    color: rgba(0,0,0,.5);
  }

  .experiences {
    margin-top: 13rem;
  }

  .experiences-main-heading {
    font-size: 13rem;
    margin-left: -2.5rem;
    overflow: hidden;
    z-index: -1;
    opacity: .05;
  }

  .skills-positioning-container {
    width: 80%;
    margin-left: -2rem; 
  }

  .container {
    margin: .5rem .5rem 1rem;
  }

  .text {
    margin-left: -.9rem;
  }

  .projects-main-heading {
    font-size: 13rem;
    opacity: .05;
    z-index: -1;
    margin-left: -3.1rem;
  }

  // ? Projects section

  .project-details-width {
    width: 80%;
    margin-left: 1.5rem;
  }

  // ? Contact section

  .contact-main-heading {
    font-size: 13rem;
    opacity: .05;
    margin-left: -5rem;
    z-index: -1;
  }
}

// ! max-width: 1225px - About section
@media only screen and (max-width: 1225px) {

  // ? About page

  .positioning-about-main-heading {
    flex-direction: column;
    margin-top: 11rem;
    width: 400px;
    p {
      margin-right: -3rem;
    }
  }

  .my-photo-positioning {
    margin-top: 11rem;
    margin-left: 6rem;
  }

  .positioning-brand-statement-paragraph {
    margin-top: 4rem;
    margin-left: -25rem;
  }

  .skills-icons-positioning {
    flex-wrap: wrap
  }

}

// ! max-width: 1024px and max-height: 800px - Medium Screen

@media screen and (max-height: 800px)  {
  @media screen and (max-width: 1024px)  {
   
    .about {
      margin-top: -20rem !important;          // I had to use !important, otherwise the iPad Pro 12' specifics were overwriting my medium screen specifics.
    }

  }
}


// ! max-width: 1024px - iPad Pro 12
@media only screen and (max-width: 1024px) {

  .scrolls {
    right: -1rem;
  }

  .overlay {
    opacity: 1;
  }

  .toggle-button-position {
    button {
      margin-top: 3.5vh;
    }
  }

  // ? Landing page

  .my-introduction-heading {
  
    margin-top: 26rem;
    .tominc-media-query {
      flex-direction: column;
    }
    h1 {
      font-size: 11rem;
      margin-top: -3rem;
      line-height: .8;
      margin-left: 2rem;
    }
    h2 {
      font-size: 4.5rem;
      margin-top: 3rem;
      line-height: .8;
      margin-left: 3.5rem;
    }
  }

  // ? About page

  .about {
    margin-top: -30rem;
  }

  .about-main-container {
    margin-left: -12rem;
  }

  .positioning-about-main-heading {
    margin-left: -1rem;
  }

  .positioning-brand-statement-paragraph {
    margin-left: -25rem;
    width: 650px;
  }


  .my-photo-positioning {
    margin-left: -3rem;
    margin-top: 25rem;
  }


  // ? Experience page

  .experiences {
    display: flex;
    align-items: center;
    margin-bottom: 16rem;
    .experiences-left-side {
      width: 108%;
      margin-left: -9.9rem;
    }
  }

  .experiences-main-heading {
    word-break: normal;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    margin-left: -1.9rem;
  }

  svg.work-experiences-icons {
    display: none;
  }


  // ? Projects page

  .projects {
    margin-bottom: 18rem;
  }

  #projects.projects-main-heading {
    margin-left: -3.4rem;
  }

  .accordion-text {
    flex-direction: column;
  }

  .project-images-resize {
    width: 600px;
    // height: 60%;
  }

  .project-image-media-query {
    display: flex;
    justify-content: center;
  }

  .tools-icons-positioning {
    justify-content: center;
  }

  .project-details-width {
    margin-bottom: 1rem;
    margin-left: -8rem;
    width: 75%;
  }

  // ? Contact page

  .contacts {
    margin-left: 1.8rem;
  }

  .container-contact {
    margin-left: -8rem;
    width: 600px;
  }

  .contact-main-heading {
    word-break: normal;
    // font-size: 14rem;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
  }

  .positioning-contact-form-media-query {
    display: flex;
  }

  // ? Side navbar

  #side-navbar {
    width: 100%;
  }

  // ? Footer

  .footer {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

}


// ! max-width: 834px (height 1194px) - iPad Pro 11'

@media screen and (max-device-height: 1194px)  {
  @media screen and (max-device-width: 834px) {

    .scrolls {
      right: -.5rem;
    }

    // ? Side navbar

    #side-navbar {
      width: 100%;
    }

    // ? About section

    .about {
      margin-top: -40rem;
    }

    .positioning-about-main-heading {
      width: 350px;
    }

    .positioning-brand-statement-paragraph {
      width: 650px;
      margin-left: -22rem;
    }

    .my-photo {
      width: 250px;
    }

    .my-photo-positioning {
      margin-left: 1rem;
    }

    // ? Experience section

    .experiences-main-heading {
      margin-top: -5rem;
    }

    // ? Projects section

    .projects {
      margin-top: -8rem;
    }

    .project-details-width {
      margin-left: -2.3rem;
    }

    .summary-positioning {
      .project-icon {
        margin-top: 2rem;
      }
      h3 {
        margin-left: 5rem;
      }
    }

    img.project-images-resize {
      height: 450px !important;
    }

    details summary::after {
      margin-top: -1.5rem !important;
    }

    // ? Contact section

    .contacts {
      margin-top: -5rem;
    }


    // ? Footer

    .footer {
      margin-top: 6rem;
    }

  } 
}

// ! max-width: 834px (height 1112px) - iPad Pro 10.5'

@media only screen and (min-height: 1112px)  {
  @media only screen and (max-width: 834px) {

    // ? About section

    .positioning-about-main-heading {
      width: 350px;
    }

    .positioning-brand-statement-paragraph {
      width: 650px;
      margin-left: -22rem;
    }

    .my-photo {
      width: 250px;
    }

    .my-photo-positioning {
      margin-left: 1rem;
    }

  }

} 

// ! max-width: 800px

@media only screen and (width: 800px) {

  // ? Side navbar

  #side-navbar {
    width: 100%;
  }

  // ? Home section

  .my-introduction-heading {
    h1 {
      font-size: 10rem;
    }
    h2 {
      font-size: 4.3rem;
    }
  }

  // ? About section

  .my-photo-positioning {
    margin-left: -4rem;
    margin-top: 26rem;
  }

  .positioning-brand-statement-paragraph {
    width: 600px;
  }

  img.my-photo {
    width: 300px;
  }

  // ? Experience 

  .experiences {
    display: flex;
    align-items: center;
    h2.experiences-main-heading {
      margin-left: -.5rem;
      word-break: normal;
      font-size: 14rem;
      transform: rotate(180deg);
      writing-mode: vertical-lr;
    }
    .experiences-left-side {
      width: 109%;
      margin-left: -9.3rem;
    }
  }

  .container-contact {
    width: 600px;
  }

  // ? Footer

  .footer {
    margin-top: 7rem;
  }
}


// ! max-width: 768px (height 1024px) - iPad Mini  / iPad

@media only screen and (min-height: 1024px) {
  @media only screen and (max-width: 768px){ 

    .toggle-button-position {
      button {
        margin-top: 6vh;
      }
    }

    // ? Home section

    .my-introduction-heading {
      h1 {
        font-size: 10rem;
      }
    }

    // ? About section

    p.positioning-brand-statement-paragraph {
      width: 600px;
    }

    img.my-photo-positioning {
      margin-top: 26rem;
      margin-left: -7rem;
    }

    // ? Experience 

    .experiences {
      display: flex;
      align-items: center;
      h2.experiences-main-heading {
        margin-left: -1.3rem;
      }
      .experiences-left-side {
        width: 109%;
        margin-left: -8.6rem;
      }
    }

    // ? Projects

    .project-details-width {
      margin-left: -8.4rem;
    }

    // ? Contacts

    .contacts-media-query-smaller-devices {
      display: flex;
      align-items: center;
      justify-content: center;
      .contact-main-heading {
        word-break: normal;
        transform: rotate(180deg);
        writing-mode: vertical-lr;
        margin-left: -.1rem;
      }
    }

    .container-contact {
      width: 600px;
      margin-left: -8.4rem;
    }

    // ? Footer

    .footer {
      margin-top: 4rem;
    }

  }
}


// ! max-width: 600px


@media only screen and (max-width: 600px) {

  // ? Home section

  .my-introduction-heading {
    h1 {
      font-size: 7rem;
    }
    h2 {
      font-size: 3.5rem;
    }
  }

  // ? About section

  .about {
    margin-top: -33rem;
  }

  .my-photo-positioning {
    margin-left: -13rem;
    margin-top: 29rem;
  }

  .positioning-brand-statement-paragraph {
    margin-top: 2rem;
    width: 450px;
  }

  .my-photo {
    width: 250px;
  }

  // ? Experience 

  .experiences {
    display: flex;
    align-items: center;
    .experiences-main-heading {
      width: 100%;
      margin-left: -15rem;
    }
    .experiences-left-side {
      width: 130%;
      margin-left: -12.4rem;
    }
  }

  .text {
    font-size: 18px;
  }

  .container {
    margin: .5rem;
  }

  .skills-positioning-container {
    width: 85%;
  }

  // ? Projects

  .project-details-width {
    margin-left: -10.3rem;
    width: 80%;
    .project-images-resize {
      width: 450px;
    }
    details summary::after {
      margin-top: 0;
    }
  }

  // ? Contacts

  .container-contact {
    width: 500px;
    margin-left: -10.7rem;
  }

  // ? Footer

  .footer {
    margin-top: 7rem;
    p {
      text-align: center;
    }
  }

}


@media only screen and (max-width: 428px) {
  .my-introduction-heading {
    margin-top: 20rem;
    h1 {
      font-size: 5rem;
      margin-left: 1rem;
    }
    h2 {
      font-size: 3.2rem;
      margin-left: 1.3rem;
    }
  }

  .scrolls {
    position: fixed;
    top: 95%;
    transform: translateY(-50%);
    right: 40%;
    margin-right: 0;
  }

  .toggle-button-position {
    button {
      margin-top: 5vh;
    }
  }

  #hamburger {
    top: 1rem;
    right: 1rem;
  }

  // ? About section

  p.positioning-about-main-heading {
    font-size: 1.5rem;
    margin-left: -1.5rem;
    width: 340px;
  }

  img.my-photo {
    margin-top: 24rem;
    margin-left: -19rem;
  }

  p.positioning-brand-statement-paragraph {
    width: 340px;
    font-size: 1.2rem;
    margin-left: -21rem;
  }

  .container {
    width: 60px;
    margin: .75rem;
    .skills-icons {
      width: 100%;
    }
    .overlay {
      margin-top: 5rem;
      margin-left: 1.5rem;
    }
  }

  .skills-positioning-container {
    h3 {
      font-size: 1.5rem;
    }
    h4 {
      font-size: 1.3rem;
    }
  }


  // ? Experiences

  .experiences {
    .experiences-main-heading {
      margin-left: -.5rem;
    }
    .experiences-left-side {
      width: 225%;
      margin-left: -13.2rem;
    }
    .experiences-description {
      h3 {
        font-size: 1.5rem;
      }
      h5 {
        font-size: 1.2rem;
      }
    }
    .experiences-description li {
      font-size: 1.2rem;
    }
  }

  // ? Projects section

  .project-details-width {
    margin-left: -11rem;
    .changing-according-profile {
      width: 107%;
    }
    .project-images-resize {
      width: 330px;
      margin-left: -1.3rem;
    }
    .tools-icons-positioning {
      flex-wrap: wrap;
    }
    p.accordion-text-paragraph {
      font-size: 1.2rem;
    }
    .positioning-projects-buttons ul li .cta svg {
      top: -50%;
      margin-left: 20px;
    }
    .summary-positioning {
      h3 {
        font-size: 1.3rem;
      }
    }
    details summary {
      width: 93%;
    }
    details summary::after {
      font-size: 2rem;
    }
  }

  // ? Contact section

  .container-contact {
    width: 350px;
    margin-left: -12rem;

    .name {
      float: initial;
      width: 100%;
    }

    .email {
      float: initial;
      width: 100%;
    }
  }

  // ? Footer section

  .footer {
    p {
      text-align: center;
    }
  }

}



// // ! max-width: 414px - iPhone 6/7/8 Plus

// @media only screen and (max-device-height: 736px){
//   @media only screen and (max-device-width: 414px){


//     // ? Landing page

//     nav {
//       margin-right: .7rem;
//     }

//     .top-navbar, .bottom-navbar {
//       margin-top: .5rem;
//     }

//     .top-navbar a h2, .bottom-navbar a h2 {
//       font-size: 4rem;
//       line-height: 1;
//     }

//     .putting-margin-to-home-page {
//       width: 135%;
//     }

//     .my-introduction-heading {
//       margin-left: .5rem;
//       margin-top: 30rem;
//       h1 {
//         font-size: 10.5rem;
//       }
//       h2 {
//         font-size: 6rem;
//         margin-left: 1rem;
//       }
//     }

//     // ? About page

//     .about {
//       margin-top: 8rem;
//     }

//     .projects-main-heading {
//       font-size: 20rem;
//       margin-top: 10rem;
//       opacity: .1 !important;
//       z-index: 1;
//       margin-left: 5rem !important;
//       color: rgba(0, 0, 0, .5);
//     }

//     .about-container-media-query {
//       margin-left: -5rem;
//     }

//     .about-paragraph {
//       p {
//         font-size: 2rem;
//         margin-right: -5rem;
//       }
//     }

//     .text {
//       font-size: 1.5rem;
//     }

//     .overlay {
//       margin-top: 7rem;
//       margin-left: .4rem;
//     }

//     .positioning-brand-statement-paragraph {
//       margin-left: -32.5rem;
//     }

//     .skills-positioning-container {
//       width: 100%;
//       margin-left: 4.5rem;

//       h3 {
//         font-size: 2.8rem;
//       }
//       h4 {
//         font-size: 2.3rem;
//       }
//     }

//     .skills-icons {
//       width: 80px;
//       padding: .5rem;
//     }

//     .skills-icons-positioning {
//       width: 115%;
//     }

//     // ? Experience page

//     .experiences {
//       display: flex;
//       .experiences-media-query-phone {
//         margin-right: -50rem;
//         width: 175%;
//       }
//     }

//     .experiences-main-heading {
//       font-size: 20rem;
//       // transform: rotate(180deg);
//       // writing-mode: vertical-lr;
//       text-align: center;
//       opacity: .1 !important;
//       margin-left: -5.2rem;
//       margin-right: -19.8rem;
//       z-index: 1;
//       color: rgba(0, 0, 0, .5);
//     }

//     .experiences-description {
//       h3 {
//         font-size: 2.6rem;
//       }
//       h5 {
//         font-size: 1.9rem;
//       }
//       li {
//         font-size: 2rem;
//         margin-left: -.8rem;
//       }
//       ul li{
//         padding-left: 1em;
//         position: relative; 
//       }
    
//       ul li:before {
//         content: '-';
//         position: absolute;
//         left: 0;
//     }
//     }

//     // ? Projects page

//     .projects {
//       width: 210%;
//     }

//     .project-details-width {
//       margin-left: -1.5rem;
//     }

//     .project-images-resize {
//       width: 680px;
//     }

//     .accordion-text-paragraph {
//       font-size: 2rem;
//     }

//     // ? Contact page

//     .contacts {
//       margin-bottom: 15rem;
//     }

//     .contact-main-heading {
//       margin-left: -5rem;
//       font-size: 12rem;
//       color: rgba(0, 0, 0, .5);
//       opacity: .1;
//     }

//     .positioning-contact-details {
//       p {
//         font-size: 2rem;
//       }
//       margin-left: -4rem;
//     }

//     // ? Footer
//     .footer {
//       width: 200%;
//       margin-left: -1rem;
//       p {
//         font-size: 1.5rem;
//       }
//     }
//   }
// }


// // ! max-width: 375px - iPhone X

// @media only screen and (max-width: 375px) {

//   // ? Landing page

//   #nav-icon {
//     margin-right: 2rem;
//   }

//   .scroll-home {
//     right: 4rem;
//     background-size: 50px;
//   }

//   .homepage-container {
//     width: 160%;
//   }

//   .top-navbar a h2, .bottom-navbar a h2 {
//     font-size: 4.5rem;
//   }

//   .my-introduction-heading {
//     margin-top: 35rem;
//     h1 {
//       font-size: 11rem;
//     }
//     h2 {
//       font-size: 6.5rem;
//     }
//   }

//   // ? About page

//   .about {
//     margin-top: 20rem;
//   }

//   .positioning-about-main-heading {
//     font-size: 3rem;
//     width: 700px;
//   }

//   .about-paragraph {
//     .my-photo-positioning {
//       margin-top: 28rem;
//       margin-left: -21rem;
//     }
//     p {
//       font-size: 2.2rem;
//       margin-right: 1.5rem;
//       margin-left: -45rem;
//     }
//     .positioning-brand-statement-paragraph {
//       width: 650px;
//     }
//   }

//   .skills-positioning-container {
//     width: 90%;
//     margin-left: 1rem;
//   }

//   // ? Experience page

//   .experiences .experiences-media-query-phone {
//     margin-right: -50rem;
//     width: 200%;
// }

//   .dashed {
//     .accordion-text-paragraph {
//       margin-left: -2.5rem;
//     }
//   }

//   .about-container-media-query {
//     margin-left: -4.5rem;
//   }

//   .projects-main-heading {
//     margin-left: 8rem !important;
//   }

//   // ? Projects page

//   .projects {
//     width: 200%;
//   }

//   .project-details-width {
//     width: 130%;
//   }

//   // ? Contact page

//   .contact-main-heading {
//     width: 950px;
//     overflow: hidden;
//     font-size: 20rem;
//     overflow-wrap: break-word;
//     line-height: .8;
//     margin-left: -3rem;
//   }

//   .container-contact {
//     width: 200%;
//     margin-left: 4rem;
//     margin-bottom: 30rem;
//   }

//   // ? Footer
//   .footer {
//     margin-left: 1rem;
//   }


// }

