/* ! Footer */
.footer {
  clear: both;
  position: relative;
  height: 4rem;
  margin-top: -4rem;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;

  p {
    font-family: 'didot';
    font-size: 1.1rem;
  }
}

// body {
//   font-family: 'Open Sans', sans-serif;
// }


