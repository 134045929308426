// ! Fonts

@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap');


/* ! Experiences & Education */
.experiences {
  min-height: 100vh;
}

.experiences-main-heading, .contact-main-heading {
  font-size: 13rem;
  letter-spacing: .3rem;
  text-transform: uppercase;
  text-align: center;
  font-family: "didot";
  transition: .4s;
  opacity: .4;
}

.heading-two {
  opacity: .1;
  margin-top: 4.2rem;
  text-align: right;
}

.experiences-education-wannabe-table {
  width: 80%;
  min-height: 500px;
  margin: 4rem auto;
}

.experiences-media-left-side {
  display: flex;
  justify-content: center;
  align-items: center;
}

.experiences-left-side {
  width: 50%;
  padding: 2rem;
  transition: 2s;
}

.education-right-side {
  width: 50%;
  padding: 2rem;
}

.experiences-description {
  h3 {
    font-size: 1.5rem;
    font-family: didot;
  }
  .span-slash {
    color: rgb(114, 0, 0);
  }
  h5 {
    font-size: 1.2rem;
    font-family: didot;
    padding-bottom: .5rem;
  }
  li {
    font-size: 1.3rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 100;
    // letter-spacing: .1rem;
  }
  .heading-positioning-experience {
    display: flex;
  }
}

.positioning-about-main-heading {
  font-family: 'DM Serif Display', serif;
  font-size: 2rem;
}

.about-paragraph {
  p {
    font-family: 'Raleway', sans-serif;
    font-size: 1.5rem;
  }
}

.experiences-two-padding {
  padding-bottom: 2rem;
}

.experiences-right-side {
  display: flex;
  // align-items: center;
  margin: 0 auto;
  svg.work-experiences-icons {
    width: 300px;
    height: 300px;
    opacity: .4;
    // background: url('../images/SVGIcons/work.svg');
    // background-size: 300px;
    // opacity: 0;
    // transition: 4s;
  }
}

// .experiences-right-side:hover,
// .experiences-right-side:active {
//   .work-experiences-icons {
//     opacity: .5;
//     animation: open 4s ease-in-out;
//   }
// }

.experience-sign {
  visibility: hidden;
}


